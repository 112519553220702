import { Checkbox, FormControlLabel, FormGroup, Radio } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Hoster } from '../../interfaces/Hoster';
import { IOffer } from '../../offers/interfaces/offer';
import { OfferType } from '../../offers/libs/offerType';
import { Offers } from '../../offers/types/offers.enum';

interface OfferProps {
  hoster: Partial<Hoster>;
  onChange: (key: string, value: boolean) => void;
  items: IOffer[];
  setHoster: React.Dispatch<React.SetStateAction<Partial<Hoster>>>;
}

const Offer: React.FC<OfferProps> = (props) => {
  // Implement the Offer component logic here
  const [activeOffer, setActiveOffer] = useState<IOffer | null>(null);
  useEffect(() => {
    if (props.hoster && props.hoster.subscriptionStatus) {
      if (activeOffer && activeOffer.id === Offers.Custom) return;
      const offerType = OfferType.getOfferType(props.hoster.subscriptionStatus);
      const offer = props.items.find((offer) => offer.id === offerType);
      if (offer) setActiveOffer(offer);
    }
  }, [props.hoster]);
  const renderDetails = (offer: IOffer | null) => {
    if (!offer || !offer.details) return null;
    const filteredDetails = offer.details.filter((detail) => detail.externalId);
    const order = ["police_record", "voip"];
    filteredDetails.sort((a, b) => {
      return order.indexOf(b.id) - order.indexOf(a.id);
    });

    return filteredDetails.map((detail, index) => {
      // Rest of the code remains the same
      return (
        <FormControlLabel
          key={detail.id}
          control={
            <Checkbox
              checked={(props.hoster.subscriptionStatus as any)[detail.externalId!] || false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                props.onChange(detail.externalId!, e.target.checked);
              }}
            />
          }
          label={detail.name}
        />
      );
    });
  };

  const handleChangeOffer = (newOffer: Offers) => {
    const offer = props.items.find((offer) => offer.id === newOffer);
    if (!offer) return console.error(`Offer ${offer} not found`);
    setActiveOffer(offer);
    if (offer.id === Offers.Custom) return;
    const subStatus = OfferType.getSubscriptionStatusFromOfferType(
      newOffer,
      props.hoster?.subscriptionStatus
    );
    if (subStatus) {
      props.setHoster({ ...props.hoster, subscriptionStatus: subStatus });
    }
  };

  const renderOffers = (offers: IOffer[]) => {
    if (!offers) return null;
    return offers.map((offer) => {
      return (
        <FormControlLabel
          key={offer.id}
          control={
            <Radio
              checked={offer.id === activeOffer?.id}
              onChange={() => handleChangeOffer(offer.id as Offers)}
            ></Radio>
          }
          label={offer.name}
        />
      );
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          margin: '10px 20px 10px 20px',
        }}
      >
        <div>
          <span style={{ fontWeight: 'bold' }}>Offres</span>
          <FormGroup style={{ marginTop: '10px' }}>{renderOffers(props.items)} </FormGroup>
        </div>
        <div
          style={{ width: '1px', height: 'auto', background: 'grey', margin: '0px 50px 0px 50px' }}
        ></div>
        <div>
          <span style={{ fontWeight: 'bold' }}>Détails</span>
          <FormGroup style={{ marginTop: '10px' }}>{renderDetails(activeOffer)} </FormGroup>
        </div>
      </div>
    </>
  );
};

export default Offer;
